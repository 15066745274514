import type { ParsedUrlQuery } from 'querystring';
import qs from 'querystring';

import add from 'date-fns/add';

import type { IAbTestingExperiments } from '@sravni/ab-testing-sdk/lib/browser';
import UTMZ from '@sravni/server-utils/lib/utmz';

import type { AffSub4 } from '@src/@types/microcredits';
import { Experiments } from '@src/constants/abTest';
import type { CookieModel } from '@src/modules/cookie';
import { Cookie, CookieKey } from '@src/modules/cookie';
import { getGaIdFromCookies, getYandexMetricsId } from '@src/utils/analytics';

import { getAffId } from './helpers';

const TYPES = {
    microcredit: 'Микрокредиты',
};

const KEYS = {
    SUB: 'adv_sub',
    SOURCE: 'source',
    PATH: 'aff_sub2',
    UTM_MARKS: 'aff_sub3',
    PRODUCT: 'aff_sub4',
    TYPE: 'aff_sub5',
    AFF_CLICK: 'aff_click_id',
    AFF_ID: 'aff_id',
    GAID: 'aff_sub',
    USER_ID: 'aff_unique1',
    MIND_BOX_UUID: 'aff_unique2',
};

type LinkKeys = typeof KEYS[keyof typeof KEYS];

export interface IMakePixelProps {
    sub?: string;
    type?: string;
    source?: string;
    mindboxUUID?: string;
    cookie: CookieModel;
    pathname: string;
    userId?: string;
    isWL?: boolean;
    route: string;
    pixelType?: string;
    abTestingSdkExperiments: IAbTestingExperiments | null;
    affSub4?: AffSub4;
    query: ParsedUrlQuery;
    shouldGetAffId?: boolean;
    affClickId?: string;
}

const updateLinkQuery = (link: string, query: Record<LinkKeys, string | undefined>) => {
    const [url, originalQueryString = ''] = link.split('?');

    const propertiesString = qs.stringify({
        ...qs.parse(originalQueryString),
        ...query,
    });

    return `${url}?${propertiesString}`;
};

interface IGetUTMZParams {
    url?: string;
    utmzCookie?: string;
}

const getUTMZ = ({ utmzCookie, url }: IGetUTMZParams) => {
    if (utmzCookie) {
        return utmzCookie;
    }

    if (!url) {
        return null;
    }

    const utmMarksObject = UTMZ.getFromUrl(decodeURIComponent(url));
    const utmMarksString = UTMZ.createCookieString(utmMarksObject);

    return encodeURIComponent(utmMarksString);
};

const getClId = (key: string, value: string | undefined | string[]) => {
    if (value) {
        return `${key}|${value}`;
    }

    return undefined;
};

const getAffClickId = ({ affClickIdCookie, url }: { affClickIdCookie?: string; url: string }) => {
    const [_, originalQueryString] = url.split('?');
    const { yclid, fbclid, gclid, rb_clickid, aff_click_id } = qs.parse(originalQueryString);

    if (aff_click_id) {
        Cookie.set(CookieKey.aff_click_id, aff_click_id as string, { expires: add(new Date(), { days: 3 }) });
    }

    return (
        getClId('yclid', yclid) ||
        getClId('fbclid', fbclid) ||
        getClId('gclid', gclid) ||
        getClId('rb_clickid', rb_clickid) ||
        (aff_click_id as string) ||
        affClickIdCookie
    );
};

const getAbTestingSdkExperimentsValue = (abTestingSdkExperiments: IAbTestingExperiments | null) => {
    const mfoExperiments: string[] = Object.values(Experiments);

    return Object.entries(abTestingSdkExperiments || {})
        .filter(([key]) => mfoExperiments.includes(key))
        .map(([key, value]) => `${key}.${value}`)
        .join('|');
};

const getAffSub = (cookie: CookieModel, isWL: boolean | undefined, gaCookie: string | undefined) => {
    const yandexMetricsId = getYandexMetricsId(cookie, Boolean(isWL));
    const affSubGoogle = gaCookie && `ga_${getGaIdFromCookies(gaCookie) || ''}`;
    const affSubYandex = yandexMetricsId ? `ym_${yandexMetricsId}` : '';

    return `${affSubGoogle}${affSubYandex ? `|${affSubYandex}` : ''}`;
};

// eslint-disable-next-line max-statements,complexity
export const makePixelLink = (link: string, props: IMakePixelProps): string => {
    const {
        sub,
        source = 'search',
        cookie,
        pathname,
        userId,
        isWL,
        route,
        pixelType,
        abTestingSdkExperiments,
        affSub4,
        query,
        shouldGetAffId = true,
        affClickId,
    } = props || {};

    const gaCookie = cookie?.[CookieKey._ga];
    const utmzCookie = cookie?.[CookieKey.__utmz];
    const affClickIdCookie = cookie?.[CookieKey.clid] || cookie?.[CookieKey.aff_click_id];

    const utmz = getUTMZ({ url: pathname, utmzCookie });
    const affClId = affClickId || getAffClickId({ affClickIdCookie, url: pathname });

    const patch: Record<LinkKeys, string | undefined> = {
        [KEYS.SUB]: sub,
        [KEYS.TYPE]: TYPES.microcredit,
        [KEYS.SOURCE]: source,
        [KEYS.MIND_BOX_UUID]: cookie.mindboxDeviceUUID,
    };

    if (cookie && gaCookie) {
        patch[KEYS.GAID] = getAffSub(cookie, isWL, gaCookie);
    }

    if (pathname) {
        const [path] = pathname.split('?');
        patch[KEYS.PATH] = path.endsWith('/') ? path : `${path}/`;
    }

    if (affSub4) {
        patch[KEYS.PRODUCT] = Object.values(affSub4).join('|');
    }

    if (userId) {
        patch[KEYS.USER_ID] = userId;
    }

    if (utmz) {
        patch[KEYS.UTM_MARKS] = utmz;
    }

    if (abTestingSdkExperiments) {
        const abTestingSdkExperimentsValue = getAbTestingSdkExperimentsValue(abTestingSdkExperiments);

        patch[KEYS.TYPE] += abTestingSdkExperimentsValue && `|AB_${abTestingSdkExperimentsValue}`;
    }

    const affId = shouldGetAffId ? getAffId(route, pathname, query) : undefined;

    if (affId) {
        patch[KEYS.AFF_ID] = affId;
    }

    if (affClId) {
        patch[KEYS.AFF_CLICK] = affClId;
    }

    return updateLinkQuery(link, patch);
};
